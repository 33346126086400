import ImageUpload from '@/components/ImageUpload/index.vue';
import AvatarService from '@/services/avatar.service.js';
import {bus} from '@/helpers/bus';

const service = new AvatarService();

export default {
    name: 'image-picker',
    components: {ImageUpload,},
    props: ['editPhoto', 'onImageUploaded'],
    data() {
        return {
            tabComponent: '',
            types: [],
            selected: null,
            type: null,
            avatars: [],
        }
    },
    computed: {},

    async mounted() {
        this.tabComponent = 'avatars';

        await this.reloadType();

    },

    watch: {
        type: async function () {
            await this.reloadType();
        }
    },

    async beforeMount() {
        const res = await service.categories();

        if (res && !res.error) {
            this.types = res.data;
            if (this.types.length > 0) this.type = this.types[0];
        }
    }
    ,
    methods: {
        async reloadType() {
            const res = await service.avatars(this.type);

            if (res && !res.error) {
                this.avatars = res.data;
            }
        },

        async setPhoto(avatar) {
            this.selected = avatar._id;
            bus.$emit('IMAGE_PICKER_CHANGE_IMAGE', {Name: avatar.Name});
        }
    },

    beforeCreate() {
        bus.$on('IMAGE_UPLOADER_CHANGE', avatar => {
            bus.$emit('IMAGE_PICKER_CHANGE_IMAGE', {Name: avatar.Name});
        });
    },

    beforeDestroy() {
        bus.$off('IMAGE_UPLOADER_CHANGE');
    },
}
